import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Page/Home.vue";

const routes = [
  // common routes
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Page/Profile/Profile.vue"),
  },

  {
    path: "/business-information",
    name: "BusinessInformation",
    component: () => import("../views/Page/Profile/BusinessInfo.vue"),
  },
  {
    path: "/company-information",
    name: "CompanyInformation",
    component: () => import("../views/Page/Profile/CompanyInfo.vue"),
  },

  {
    path: "/oprational-information",
    name: "OprationalInformation",
    component: () => import("../views/Page/Profile/OprationalInformation.vue"),
  },

  {
    path: "/add-new-container-on-wheel",
    name: "NewContainerOnWheel",
    component: () => import("../views/Page/Container/wheel.vue"),
  },
  {
    path: "/edit-container-on-wheel/:id",
    name: "EditContainerOnWheel",
    component: () => import("../views/Page/Container/EditContainerOnWheel.vue"),
  },
  {
    path: "/edit-container-on-wheel-childe/:id",
    name: "EditContainerOnWheelChiled",
    component: () =>
      import("../views/Page/Container/EditContainerOnWheelChiled.vue"),
  },
  {
    path: "/view-more-list/:id",
    name: "ViewMoreList",
    component: () => import("../views/Page/Container/ViewMoreList.vue"),
  },
  {
    path: "/active-containers",
    name: "ActiveContainers",
    component: () => import("../views/Page/Container/ContainerList.vue"),
  },

  {
    path: "/notification-list",
    name: "NotificationList",
    component: () => import("../views/Page/Notifications/Notification.vue"),
  },

  // For Transporter

  {
    path: "/booking-details/:id",
    name: "BookingDetails",
    component: () =>
      import("../views/Page/Transporter/Bookings/BookingDetails.vue"),
  },

  {
    path: "/history",
    name: "History",
    component: () => import("../views/Page/Transporter/History/History.vue"),
  },

  {
    path: "/container-under-booking",
    name: "CcontainerUnderBooking",
    component: () =>
      import(
        "../views/Page/Transporter/TransporterBooking/ContainerUnderBooking.vue"
      ),
  },

  {
    path: "/container-under-booking-detail/:id",
    name: "ContainerUnderBookingDetail",
    component: () =>
      import(
        "../views/Page/Transporter/TransporterBooking/ContainerUnderBookingDetail.vue"
      ),
  },
  {
    path: "/send-photos/:id",
    name: "SendPhotos",
    component: () =>
      import("../views/Page/Transporter/TransporterBooking/SendPhotos.vue"),
  },

  {
    path: "/linking-request/:id",
    name: "TransporterLinkingRequest",
    component: () =>
      import("../views/Page/Transporter/TransporterBooking/LinkingRequest.vue"),
  },

  {
    path: "/start-moving-towords-exporter/:id",
    name: "StartMovingTowordsExporter",
    component: () =>
      import(
        "../views/Page/Transporter/TransporterBooking/StartMovingTowordsExporter.vue"
      ),
  },

  {
    path: "/exporter-details/:id",
    name: "ExporterDetails",
    component: () =>
      import(
        "../views/Page/Transporter/TransporterBooking/ExporterDetails.vue"
      ),
  },

  {
    path: "/on-way-to-exporter/:id",
    name: "OnWayToExporter",
    component: () =>
      import(
        "../views/Page/Transporter/TransporterBooking/OnWayToExporter.vue"
      ),
  },
  {
    path: "/upload-invoice-and-lori-recipt/:id",
    name: "UploadInvoiceAndLR",
    component: () =>
      import(
        "../views/Page/Transporter/TransporterBooking/UploadInvoiceAndLR.vue"
      ),
  },
  {
    path: "/trip-close/:id",
    name: "TripClose",
    component: () =>
      import("../views/Page/Transporter/TransporterBooking/TripClose.vue"),
  },

  {
    path: "/reject-or-cancel/:id",
    name: "RejectedOrCancelled",
    component: () =>
      import(
        "../views/Page/Transporter/TransporterBooking/RejectedOrCancelled.vue"
      ),
  },

  {
    path: "/payment-pending",
    name: "TransporterPaymentPending",
    component: () =>
      import("../views/Page/Transporter/Payment/PaymentPending.vue"),
  },

  {
    path: "/reuse-container/add-details",
    name: "/AddDetails",
    component: () =>
      import("../views/Page/Transporter/ReuseContainer/AddDetails.vue"),
  },

  {
    path: "/reuse-container/edit-details/:id",
    name: "/EditDetails",
    component: () =>
      import("../views/Page/Transporter/ReuseContainer/EditDetails.vue"),
  },

  {
    path: "/reuse-container/linking-request",
    name: "/ReuseContainerLinkingRequest",
    component: () =>
      import("../views/Page/Transporter/ReuseContainer/LinkingRequest.vue"),
  },

  {
    path: "/reuse-container/linking-payments",
    name: "/ReuseLinkingPaymnets",
    component: () =>
      import("../views/Page/Transporter/ReuseContainer/LinkingPaymnets.vue"),
  },

  {
    path: "/reuse-container/linking-payments-details/:id",
    name: "/LinkingPaymnetsDetails",
    component: () =>
      import(
        "../views/Page/Transporter/ReuseContainer/LinkingPaymnetsDetails.vue"
      ),
  },

  {
    path: "/reuse-container/history",
    name: "/ReuseHistory",
    component: () =>
      import("../views/Page/Transporter/ReuseContainer/History.vue"),
  },

  // Delinking Process
  {
    path: "/reuse-container/delinking-request-list",
    name: "/ReuseDeLinkingList",
    component: () =>
      import("../views/Page/Transporter/ReuseContainer/DeLinking.vue"),
  },
  {
    path: "/reuse-container/delinking-request-history",
    name: "/ReuseDeLinkingHistory",
    component: () =>
      import("../views/Page/Transporter/ReuseContainer/DeLinkingHistory.vue"),
  },

  // Exporter Routes

  {
    path: "/container-search",
    name: "ContainerSearch",
    component: () => import("../views/Page/Container/SearchContainer.vue"),
  },

  {
    path: "/containers",
    name: "InfoSearchContainePrice",
    component: () =>
      import("../views/Page/Container/InfoSearchContainePrice.vue"),
  },

  {
    path: "/make-booking-container/:id",
    name: "BookingContainer",
    component: () => import("../views/Page/Container/BookingContainer.vue"),
  },
  {
    path: "/confirme-booking",
    name: "ConfirmBooking",
    component: () => import("../views/Page/Container/ConfirmBooking.vue"),
  },

  {
    path: "/booking-list-exporter",
    name: "BookingListExporter",
    component: () => import("../views/Page/Exporter/BookingList.vue"),
  },

  {
    path: "/container-photo/:id",
    name: "ContainerPhotos",
    component: () => import("../views/Page/Exporter/ContainerPhotos.vue"),
  },

  {
    path: "/payment-page",
    name: "PaymentBooking",
    component: () => import("../views/Page/Exporter/PaymentBooking.vue"),
  },
  {
    path: "/exporter-container-under-booking",
    name: "CcontainerUnderBookingExporter",
    component: () =>
      import(
        "../views/Page/Exporter/ExporterBooking/ContainerUnderBooking.vue"
      ),
  },

  {
    path: "/exporter-booking-request/:id",
    name: "ExporterBookingRequest",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/BookingRequest.vue"),
  },
  {
    path: "/exporter-container-photos/:id",
    name: "ContainerPhotos",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/ContainerPhotos.vue"),
  },

  {
    path: "/exporter-linking-request/:id",
    name: "LinkingRequest",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/LinkingRequest.vue"),
  },

  {
    path: "/exporter-linking-payments/:id",
    name: "LinkingPayment",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/LinkingPayment.vue"),
  },

  {
    path: "/transporter-detaile-page/:id",
    name: "TransporterDetails",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/TransporterDetails.vue"),
  },

  {
    path: "/exporter-container-reached/:id",
    name: "ContainerReached",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/ContainerReached.vue"),
  },
  {
    path: "/exporter-download-invoice/:id",
    name: "DownloadInvoice",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/DownloadInvoice.vue"),
  },
  {
    path: "/exporter-trip-close/:id",
    name: "ExporterTripClose",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/TripClose.vue"),
  },

  {
    path: "/exporter-reject-or-cancel/:id",
    name: "ExpporterRejectedOrCancelled",
    component: () =>
      import("../views/Page/Exporter/ExporterBooking/RejectedOrCancelled.vue"),
  },

  {
    path: "/exporter-history",
    name: "ExporterHistory",
    component: () => import("../views/Page/Exporter/History/History.vue"),
  },

  {
    path: "/exporter-payment-pending",
    name: "ExporterPaymentPending",
    component: () =>
      import("../views/Page/Exporter/Payment/PaymentPending.vue"),
  },

  {
    path: "/thank-you-booking-page",
    name: "ExporterThankyouPage",
    component: () =>
      import("../views/Page/Exporter/Thankyou/ExporterThankYou.vue"),
  },

  // Shipping  Line Routes

  {
    path: "/linking-request-received",
    name: "LinkingRequestReceived",
    component: () =>
      import(
        "../views/Page/ShipingLine/ShipingLineBooking/LinkingRequestReceived.vue"
      ),
  },
  {
    path: "/delinking-request-received",
    name: "DeLinkingRequestReceived",
    component: () =>
      import(
        "../views/Page/ShipingLine/ShipingLineBooking/DelinkingRequestReceived.vue"
      ),
  },
  {
    path: "/shipping-line-history",
    name: "ShippingLineHistory",
    component: () => import("../views/Page/ShipingLine/History/History.vue"),
  },

  // reuse container

  {
    path: "/reuse-container/linking-request-history",
    name: "/ReuseContainerShippingLineHistory",
    component: () =>
      import("../views/Page/ShipingLine/ReuseContainer/History.vue"),
  },

  {
    path: "/reuse-container/linking-request-received",
    name: "/ReuseContainerLinkingRequestReceivedShippingLine",
    component: () =>
      import("../views/Page/ShipingLine/ReuseContainer/LinkingRequest.vue"),
  },

  {
    path: "/reuse-container/delinking-request-received",
    name: "DelinkingRequestRecived",
    component: () =>
      import("../views/Page/ShipingLine/ReuseContainer/DelinkingRequest.vue"),
  },
  {
    path: "/reuse-container/shippingline-delinking-history",
    name: "DelinkingHistory",
    component: () =>
      import("../views/Page/ShipingLine/ReuseContainer/DelinkingHistory.vue"),
  },

  // {
  //   path: '/shipping-line-booking-list',
  //   name: 'ShippingLineBookingList',
  //   component: () => import('../views/Page/ShipingLine/ShippingLineBookingList.vue')
  // },

  // Auth Section

  {
    path: "/help-and-support",
    name: "HelpAndSupport",
    component: () => import("../views/Page/HelpAndSupport/HelpAndSupport.vue"),
  },
  {
    path: "/privacy-and-policy",
    name: "PrivecyAndPolicy",
    component: () => import("../views/ImportantDocc/PrivecyAndPolicy.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "TurmsAndCondition",
    component: () => import("../views/ImportantDocc/TurmsAndCondition.vue"),
  },
  {
    path: "/set-new-password/:random",
    name: "SetNewPassword",
    component: () =>
      import(
        /* webpackChunkName: "SetNewPassword" */ "../views/Auth/SetNewPassword.vue"
      ),
  },
  {
    path: "/reset-password/:random",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/Auth/ResetPassword.vue"
      ),
  },
  {
    path: "/registeration",
    name: "Registeration",
    component: () => import("../views/Page/Registeration.vue"),
  },
  {
    path: "/register-your-account",
    name: "Register",
    component: () => import("../views/Auth/Register.vue"),
  },
  {
    path: "/",
    name: "HomeLogin",
    component: () => import("../views/Auth/Login.vue"),
    meta: { headerVisible: "hide" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/Login.vue"),
    meta: { headerVisible: "hide" },
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () => import("../views/Auth/ForgetPassword.vue"),
    meta: { headerVisible: "hide" },
  },
  {
    path: "/otp-verification",
    name: "OtpVirification",
    component: () => import("../views/Auth/OtpVirification.vue"),
    meta: { headerVisible: "hide" },
  },
  {
    path: "/mobile-otp-verification",
    name: "MobileOtpLogin",
    component: () => import("../views/Auth/MobileOtpLogin.vue"),
    meta: { headerVisible: "hide" },
  },
  {
    path: "/error-404",
    name: "Error404",
    component: () => import("@/views/Error/Error404.vue"),
    meta: {
      headerVisible: "hide",
    },
  },
  {
    // path: '*',
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "/error-404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

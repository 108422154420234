<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo">
      <router-link to="/home" class="app-brand-link">
        <img style="width: 100%" src="/assets/img/logo/BOW.png" alt="" />
      </router-link>
      <a
        href="javascript:void(0);"
        class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
      >
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>
    <hr />
    <div class="menu-inner-shadow"></div>
    <TransporterNav v-if="user.businessNature == 'Transporter'" />
    <ExporterNav v-if="user.businessNature == 'Exporter'" />
    <ImporterNav v-if="user.businessNature == 'Importer'" />
    <OceanCarrierNav v-if="user.businessNature == 'Ocean Carrier'" />
    <ChaNav v-if="user.businessNature == 'CHA'" />
    <FreightForwarderNav v-if="user.businessNature == 'Freight Forwarder'" />
  </aside>
</template>
<script>
import TransporterNav from "@/components/common/TransporterNav.vue";
import OceanCarrierNav from "@/components/common/OceanCarrierNav.vue";
import ChaNav from "@/components/common/ChaNav.vue";
import ExporterNav from "@/components/common/ExporterNav.vue";
import FreightForwarderNav from "@/components/common/FreightForwarderNav.vue";
import ImporterNav from "@/components/common/ImporterNav.vue";
export default {
  name: "Sidebar",
  data() {
    return {
      user: {},
      isMenuOpen: {
        master: false,
        another: false,
      },
    };
  },
  components: {
    TransporterNav,
    OceanCarrierNav,
    ChaNav,
    ExporterNav,
    FreightForwarderNav,
    ImporterNav,
  },
  methods: {
    toggleMenu(menu) {
      this.isMenuOpen[menu] = !this.isMenuOpen[menu];
      this.updateMenuState(menu);
    },
    profileCompleateORNot() {
      this.user = JSON.parse(localStorage.getItem("userData"));
      this.role = JSON.parse(localStorage.getItem("role"));
    },
    updateMenuState(menu) {
      const menuSub = this.$el.querySelector(`.menu-sub.${menu}`);
      if (menuSub) {
        if (this.isMenuOpen[menu]) {
          menuSub.style.display = "block";
        } else {
          menuSub.style.display = "none";
        }
      }
    },
  },
  mounted() {
    this.profileCompleateORNot();
    Object.keys(this.isMenuOpen).forEach((menu) => {
      this.updateMenuState(menu);
    });
  },
  // created(){
  //   this.profileCompleateORNot()
  // }
};
</script>
<style scoped>
.menu-sub {
  display: none;
}
.menu-sub.menu-open {
  display: block;
}
</style>

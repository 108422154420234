import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ConnectionMixin from "./mixins/ConnectionMixin";
// import VueSweetalert2 from 'vue-sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';

// Set the base URL for Axios for dev
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

// Set the base URL for Axios  for live
//axios.defaults.baseURL = 'https://bow.olsys.in/api/';
axios.defaults.baseURL = 'https://api.boxonwheel.com/api/';
//axios.defaults.baseURL = "https://api-dev.boxonwheel.com/api/";

import jQuery from "jquery";
window.$ = jQuery;
window.jQuery = jQuery;

// Create the Vue application
const app = createApp(App);

// Use Vuex store, Vue Router, and VueSweetalert2
app.use(store);
app.use(router);
// Register the mixin globally
app.mixin(ConnectionMixin);
// app.use(VueSweetalert2);

// Mount the app to the DOM
app.mount("#app");

<template>
  <ul class="menu-inner py-1">
    <!-- Dashboards -->
    <li class="menu-item" :class="{ active: $route.path === '/home' }">
      <router-link
        :to="user.status == 'Approved' ? '/home' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <!-- <i class="menu-icon tf-icons bx bx-home-circle"></i> -->
        <!-- <i class="menu-icon fi fi-rr-home"></i> -->

        <i class="menu-icon fi fi-rr-dashboard"></i>
        <div data-i18n="Dashboards">Dashboards</div>
      </router-link>
    </li>
    <!-- Containers On Wheel -->
    <li
      class="menu-item"
      :class="{ active: $route.path === '/add-new-container-on-wheel' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/add-new-container-on-wheel' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-truck-container"></i>
        <div data-i18n="Port">Add Containers</div>
      </router-link>
    </li>

    <li class="menu-item">
      <span
        class="menu-link text-truncate cursor-pointer active"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="true"
        aria-controls="collapseExample"
        @click="toggleCollapse"
      >
        <i class="menu-icon fi fi-rr-recycle"></i>
        <div data-i18n="Re Use Container">Re Use Container</div>
        <i
          :class="isCollapsed ? 'fi fi-rr-angle-right' : 'fi fi-rr-angle-down'"
          style="font-size: 12px; right: 10px; position: absolute"
        ></i>
      </span>

      <div class="collapse" id="collapseExample">
        <ul>
          <li class="menu-item text-start">
            <router-link
              to="/reuse-container/add-details"
              class="menu-link cursor-pointer"
            >
              <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
              <div data-i18n="Port">Linking Requests</div>
            </router-link>
          </li>
          <li class="menu-item text-start">
            <router-link
              :to="
                user.status == 'Approved'
                  ? '/reuse-container/delinking-request-list'
                  : '#'
              "
              class="menu-link cursor-pointer"
              :class="user.status == 'Approved' ? '' : 'text-light'"
            >
              <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
              <div data-i18n="Port">Delinking Requests</div>
            </router-link>
          </li>
        </ul>
      </div>
    </li>

    <li
      class="menu-item"
      :class="{ active: $route.path === '/active-containers' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/active-containers' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-list"></i>

        <div data-i18n="Port">Active Containers</div>
      </router-link>
    </li>

    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/container-under-booking' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/container-under-booking' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-list"></i>

        <div data-i18n="Port">Active Bookings</div>
      </router-link>
    </li>
    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/payment-pending' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/payment-pending' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <!-- <i class="menu-icon fi fi-rr-pending"></i> -->
        <i class="menu-icon fi fi-rs-transaction-globe"></i>
        <div data-i18n="Port">Payments Pending</div>
      </router-link>
    </li>
    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/notification-list' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/notification-list' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-bell-notification-social-media"></i>

        <div data-i18n="Port">Notification</div>
      </router-link>
    </li>

    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/history' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/history' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-time-past"></i>

        <div data-i18n="Port">History</div>
      </router-link>
    </li>

    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/help-and-support' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/help-and-support' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-phone-call"></i>

        <div data-i18n="Port">Help & Support</div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import axios from "axios";
export default {
  name: "TranporterNav",
  data() {
    return {
      user: {},
      collapseExample: "",
      isDropdownOpen: false, // Dropdown state
      isCollapsed: true, // default state for collapse
    };
  },
  watch: {
    // Watch for route changes
    $route(to) {
      // Keep dropdown open if the new route is within the menu's path
      if (to.path.startsWith("/reuse-container")) {
        this.isCollapsed = false;
      } else {
        this.isCollapsed = true;
      }
    },
  },

  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    isCurrentRoute(route) {
      return this.$route.path.startsWith(route);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen; // Toggle dropdown visibility
    },
    profileCompleateORNot() {
      this.isLoading = true;
      axios
        .get(
          `customer/user/${JSON.parse(localStorage.getItem("userData")).id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.user = response.data.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userData");
            localStorage.removeItem("role");
            this.$router.push({ name: "Login" });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadIsCollapsed() {
      this.isCollapsed = true;
      // if (this.$route.path == "/reuse-container/add-details") {
      //   this.isCollapsed = localStorage.getItem("isCollapsed");
      // }
    },
  },
  mounted() {
    if (this.checkConnection()) {
      this.profileCompleateORNot();
      this.loadIsCollapsed();
    }
  },
};
</script>

<style scoped>
/* .disabled-card{
        pointer-events: none !important;
      
    } */
</style>
